/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/11/2024
 */
import React from "react";

const CalendarLinkingContext = React.createContext({
  getEventUrl: (event) => {},
  onClickEvent: (event, params) => (e) => {},
  onCloseOpenedEventsDetails: (e) => {},
  selectedEvent: null,
  selectedEventSlug: null,
  originEventsSlug: null,
  showDetailsAsModal: false,
  expandDetailsAsAccordion: false,
});

export default CalendarLinkingContext;
