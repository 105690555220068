/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";
import classnames from "classnames";
import { CT } from "../../../../consts";
import classNames from "classnames";

const Card = ({}) => (
  <div
    className={classNames(
      "card-view--card",
      "dc-flex-1",
      "rev--loader-card-item",
      "rev-preloader-block"
    )}
  ></div>
);

const LoaderIndicator = ({ bubbleSize, bubbleStyle }) => {
  return (
    <>
      <Card />
      <Card />
      <Card />
      <Card />
      <Card />
    </>
  );
};

export default LoaderIndicator;
