/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/06/2022
 */

import React, { useMemo } from "react";
import PropTypes from "prop-types";
import DetailsRow from "./DetailsRow";

import RecurrenceSeriesTable from "./RecurrenceSeriesTable";
import { useTranslation } from "react-i18next";

const RecurrenceSeries = (props) => {
  const { showSeries, series, description, eventId } = props;
  const { t } = useTranslation();

  const recurrencesTable = showSeries ? (
    <RecurrenceSeriesTable
      numberOfNextEvents={props.numberOfNextEvents}
      series={series}
      eventName={props.eventName}
      eventId={eventId}
      onShowMore={props.onShowMore}
    />
  ) : null;

  return (
    <DetailsRow
      title={t("Future event times in this recurring event series")}
      icon="replay"
    >
      <p
        id={`series-${eventId}`}
        className={"dce-future-event-times-in-series"}
      >
        {t("This event repeats")} {description}.
      </p>

      {recurrencesTable}
    </DetailsRow>
  );
};

RecurrenceSeries.defaultProps = {};

RecurrenceSeries.propTypes = {
  series: PropTypes.string,
  eventId: PropTypes.string,
  eventName: PropTypes.string,
  description: PropTypes.string,
  onShowMore: PropTypes.func,
  showSeries: PropTypes.bool,
};

export default RecurrenceSeries;
