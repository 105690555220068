import { CT, IT } from "../../../consts";

const showConditionsEvent = [
  {
    field: "eventDetailsShowSubscribeOptions",
    value: 1,
    defaultValue: 1,
  },
];

const showConditionsCalendar = [
  {
    field: "calendarShowSubscribe",
    value: 1,
    defaultValue: 0,
  },
];

const showConditionsCalendarOrEvent = [
  ...showConditionsEvent,
  {
    field: "calendarShowSubscribe",
    value: 1,
    defaultValue: 0,
    or: true,
  },
];

showConditionsCalendarOrEvent[1].or = true;

const showConditionsShareDesignOptions = [
  {
    field: "popupSubscribeShow",
    value: 1,
    defaultValue: 1,
  },
  {
    field: "popupRSSLink",
    value: 1,
    defaultValue: 1,
    or: true,
  },
];
export const definitions = {
  title: (
    <span>
      Subscription <span className={"badge badge-success"}>NEW</span>
    </span>
  ),
  icon: "card_membership",
  groups: [
    {
      label: "Subscribing to a calendar",
      options: [
        {
          title: "Show subscribe button",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          applicable: "design",
          name: "calendarShowSubscribe",
          defaultValue: 0,
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Subscribe button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBtnBg",
          defaultValue: "#fff",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button background",
          templates: [CT.detailedList],
          name: "calendarSubscribeBtnBg",
          defaultValue: "#fff",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-subscribe-button"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button background on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBtnBgHover",
          defaultValue: "#D3D3D3",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button:hover"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button background on hover",
          templates: [CT.detailedList],
          name: "calendarSubscribeBtnBgHover",
          defaultValue: "#edf2f7",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-subscribe-button:hover"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button border",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBorder",
          defaultValue: "#D3D3D3",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button border",
          templates: [CT.detailedList],
          name: "calendarSubscribeBorder",
          defaultValue: "#fff",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-subscribe-button"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button border on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBtnBorderHover",
          defaultValue: "#D3D3D3",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button:hover"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button border on hover",
          templates: [CT.detailedList],
          name: "calendarSubscribeBtnBorderHover",
          defaultValue: "#fff",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-subscribe-button:hover"],
              property: "border-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: true,
            },
          },
        },
        {
          title: "Subscribe button label color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBtnLabel",
          defaultValue: "#333",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe button label color",
          templates: [CT.detailedList],
          name: "calendarSubscribeBtnLabel",
          defaultValue: "#2d3748",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe button label color on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.bubble,
          ],
          name: "calendarSubscribeBtnLabelHover",
          defaultValue: "#333",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-calendar-subscribe-button:hover"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe button label color on hover",
          templates: [CT.detailedList],
          name: "calendarSubscribeBtnLabelHover",
          defaultValue: "#2d3748",
          show: showConditionsCalendar,
          style: [
            {
              selector: [".dc-detailed-list--calendar-share-button:hover"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Subscribe to an event",
      options: [
        {
          title: "Show share options in event details",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "eventDetailsShowSubscribeOptions",
          defaultValue: 1,
          show: [{}],
          tooltip: null,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Subscribe button background",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribeItemBgColor",
          defaultValue: "#00aafb",
          show: showConditionsEvent,
          style: [
            {
              selector: [".event-row--details .share-event.share-event--link"],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe link color",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribeItemColor",
          defaultValue: "#fff",
          show: showConditionsEvent,
          style: [
            {
              selector: [".event-row--details .share-event.share-event--link"],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe link background on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribeItemHoveredBgColor",
          defaultValue: "rgba(0, 170, 251, 0.7)",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".event-row--details .share-event.share-event--link:hover",
              ],
              property: "background-color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Subscribe link color on hover",
          templates: [
            CT.eventList,
            CT.monthly,
            CT.weekly,
            CT.card,
            CT.cardList,
            CT.cardSlider,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribeItemHoveredColor",
          defaultValue: "#fff",
          show: showConditionsEvent,
          style: [
            {
              selector: [
                ".event-row--details .share-event.share-event--link:hover",
              ],
              property: "color",
            },
          ],
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: true,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },
    {
      label: "Popup design",
      options: [
        {
          title: "Background color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupBgColor",
          defaultValue: "#fff",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: ".dc-subscribe-popup--card",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Card title color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupTitleColor",
          defaultValue: "#000",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [".dc-subscribe-popup--card-section--header"],
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Section label color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupSourceColor",
          defaultValue: "#000",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: ".dc-subscribe-popup--card-section--subheader",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Separator color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupSeparatorColor",
          defaultValue: "#e0e0e0",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [
              ".dc-subscribe-popup--rss-feed-container",
              ".dc-subscribe-popup--card-section--subheader",
            ],
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupCloseButtonColor",
          defaultValue: "#333",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [
              ".dc-subscribe-popup--card--close::before",
              ".dc-subscribe-popup--card--close::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
        {
          title: "Close button color on hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "subscribePopupCloseButtonColorOnHover",
          defaultValue: "#333",
          show: showConditionsCalendarOrEvent,
          style: {
            selector: [
              ".dc-subscribe-popup--card--close:hover::before",
              ".dc-subscribe-popup--card--close:hover::after",
            ],
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
        },
      ],
    },

    {
      label: "Subscribe to calendar",
      options: [
        {
          title: "Show Subscribe options",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeShow",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Show RSS link",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupRSSLink",
          defaultValue: 1,
          show: showConditionsCalendarOrEvent,
          control: {
            kind: "checkbox",
            options: {},
          },
        },
        {
          title: "Icon border color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeIconBorderColor",
          defaultValue: "#e0e0e0",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link img",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "Icon border color hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeIconBorderColorHover",
          defaultValue: "#e0e0e0",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link:hover img",
            property: "border-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "Icon background color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeIconBg",
          defaultValue: "#fff",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link img",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "Icon background hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeIconBgHover",
          defaultValue: "#fff",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link:hover img",
            property: "background-color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "Label color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeColor",
          defaultValue: "#0c0c0c",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "Label color hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupSubscribeColorHover",
          defaultValue: "#0C0C0CCE",
          style: {
            selector:
              ".dc-subscribe-popup--add-to-calendar-container .dc-subscribe-popup--add-to-calendar-link:hover",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: showConditionsShareDesignOptions,
        },
        {
          title: "RSS Icon color",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupRSSIconColor",
          defaultValue: "#F08305",
          style: {
            selector: ".dc-subscribe-popup--rss-feed-link i",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupRSSLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
        {
          title: "RSS Icon color hover",
          templates: [
            CT.eventList,
            CT.weekly,
            CT.cardList,
            CT.card,
            CT.cardSlider,
            CT.monthly,
            CT.detailedList,
            CT.bubble,
          ],
          name: "popupRSSIconColorHover",
          defaultValue: "#F08305",
          style: {
            selector: ".dc-subscribe-popup--rss-feed-link:hover i",
            property: "color",
          },
          tooltip: null,
          control: {
            kind: "color",
            options: {
              showInherit: false,
              showCustomValue: false,
              showTransparentOption: false,
            },
          },
          show: [
            ...showConditionsCalendarOrEvent,
            {
              field: "popupRSSLink",
              value: 1,
              defaultValue: 1,
            },
          ],
        },
      ],
    },
  ],
};
