/**
 * Created by piotr.pozniak@thebeaverhead.com on 15/12/2020.
 */

import React, { useCallback, useContext } from "react";
import classNames from "classnames";
import CardDateFormatter from "./CardDateFormatter";
import CardLocationFormatter from "./CardLocationFormatter";
import EventBadges from "../EventBadges";
import EventCTA from "../EventsList/EventCTA";
import CardEventHeader from "./CardEventHeader";
import PrivateEventBadge from "../PrivateEventBadge";
import CalendarLinkingContext from "../../../../contexts/CalendarLinkingContext";

/**
 * CardEvent component
 * @param props
 * @param {Object} props.event - event object
 * @param {String} props.inpicDatePlacement - placement of the date in the image
 * @param {function} props.onCardClick - callback on mouse up
 * @param {Boolean} props.showDate - show date
 * @param {Boolean} props.showTitle - show title
 * @param {Boolean} props.showLocation - show location
 * @param {Boolean} props.showDescription - show description
 * @param {Boolean} props.showSummary - show summary
 * @param {Boolean} props.cCardCtaButtonFullWidth - tells whether the CTA button should be full width
 * @param {Boolean} props.embedded - tells whether the card is calendar is embedded. if not embedded, then it's in a builder
 * @param {String} [props.hoverEffect = "zoom"] - hover effect
 * @param {Boolean} [props.showWeekDay = false] - show week day
 * @param {String} [props.weekDayFormatter = ""] - week day formatter
 * @param {Boolean} props.useEventAccent - use event accent
 * @param {Boolean} props.showImage - show image
 * @param {Boolean} props.showRecurringBadge - show recurring badge
 * @param {Boolean} props.showFeaturedBadge - show featured badge
 * @param {String} props.badgesLocation - location of the badges
 * @param {Boolean} props.showCardCta - show card CTA
 * @param {Boolean} props.showAsList - show as list
 * @param {Boolean} [props.showImageContainer = true] - show image container
 * @param {Boolean} props.cardCtaButtonWidth - card CTA button width
 * @param {Boolean} props.triggerCTAOnCardClick - trigger CTA on card click
 * @param {Boolean} props.showAccentOnCard - show accent on card
 * @returns {Element}
 * @constructor
 */
const CardEvent = ({
  hoverEffect = "zoom",
  showWeekDay = false,
  weekDayFormatter = "",
  showImageContainer = true,
  ...props
}) => {
  const { event, showCardCta, selectedDateTimeFormat } = props;

  const calendarLinking = useContext(CalendarLinkingContext);
  /**
   *
   * @param e
   */
  const onCardClick = useCallback(
    (e) => {
      // if event is coming from a clicked anchor with class dce--event-card--cta-btn then do not trigger onSelectedEvent

      if (props.onCardClick) {
        return props.onCardClick(e);
      }

      if (e.target.classList.contains("dce--event-card--cta-btn")) {
        return;
      }

      calendarLinking.onClickEvent(event)(e);
    },
    [props.onCardClick]
  );

  /**
   *
   * @type {function(*): boolean}
   */
  const onCardClickLock = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    return false;
  }, []);

  const banner =
    props.showImage && event.cover_image ? (
      <img src={event.cover_image} />
    ) : null;

  const recurringBadges =
    (props.showRecurringBadge && event.recurrence_description) ||
    props.showFeaturedBadge ? (
      <EventBadges
        showRecurringBadge={
          props.showRecurringBadge && event.recurrence_description
            ? true
            : false
        }
        showFeaturedBadge={props.showFeaturedBadge && event.is_featured}
        location={props.badgesLocation}
      />
    ) : null;

  const descriptionElement = props.showDescription ? (
    <p
      className="line-clamp"
      dangerouslySetInnerHTML={{ __html: event.description }}
    ></p>
  ) : null;

  const summaryElement = props.showSummary ? (
    <p
      className="line-clamp"
      dangerouslySetInnerHTML={{ __html: event.summary }}
    ></p>
  ) : null;

  const cardCTAElement = showCardCta ? (
    <EventCTA
      event={event}
      settingsPrefix={"cCard"}
      className={classNames("dce--card-details-item dce--card--cta")}
      buttonClassName={classNames("dce--event-card--cta-btn", {
        "dce--event-card--cta-btn-auto-width": !props.cCardCtaButtonFullWidth,
      })}
    />
  ) : null;

  const title = props.showTitle ? <h2>{event.name}</h2> : null;

  const date = props.showDate ? (
    <CardDateFormatter
      startTime={event.start_time}
      endTime={event.end_time}
      showWeekDay={props.showWeekDay}
      weekDayFormatter={props.weekDayFormatter}
      allDayEvent={event.allDay}
      selectedDateTimeFormat={selectedDateTimeFormat}
    />
  ) : null;

  const location = props.showLocation ? (
    <CardLocationFormatter event={event} />
  ) : null;

  const inlineStyle = {};

  if (props.useEventAccent && event.accentColor) {
    inlineStyle.backgroundColor = event.accentColor;
  }

  const showBadgesInImage =
    (props.showRecurringBadge || props.showFeaturedBadge) &&
    ["image-top", "image-bottom"].indexOf(props.badgesLocation) >= 0;

  const cardContent =
    title ||
    date ||
    location ||
    descriptionElement ||
    summaryElement ||
    cardCTAElement ||
    (recurringBadges && !showBadgesInImage) ? (
      <div
        className={classNames("card-view--card-content", {
          "dc-flex-1": props.badgesLocation === "title-bottom",
        })}
      >
        {recurringBadges && !showBadgesInImage ? recurringBadges : null}
        <div className={"card-view--card-content-body"}>
          {title}
          {date}
          {location}
          {descriptionElement}
          {summaryElement}
          {cardCTAElement}
        </div>
      </div>
    ) : (
      false
    );

  const cardHeader = showImageContainer ? (
    <CardEventHeader
      showBadgesInImage={showBadgesInImage}
      recurringBadges={recurringBadges}
      inlineStyle={inlineStyle}
      banner={banner}
      event={event}
      inpicDatePlacement={props.inpicDatePlacement}
    />
  ) : null;

  const privateEventBadge =
    event.is_private * 1 == 1 && !props.embedded ? <PrivateEventBadge /> : null;

  return (
    <a
      className={classNames(
        "card-view--card",
        "dc-flex-1",
        `card-view--card_hover-effect--${props.hoverEffect}`,
        {
          "card-view--card_cursor_pointer":
            props.triggerCTAOnCardClick || props.onSelectedEvent ? true : false,
          "dc-flex-col":
            props.badgesLocation === "title-bottom" && !props.showAsList,
        }
      )}
      // onMouseUp={onCardClick}
      // onMouseDown={onCardClickLock}
      onClick={onCardClick}
      href={calendarLinking.getEventUrl(event)}
    >
      {cardHeader}
      {cardContent}
      {privateEventBadge}
      <div className={"card_hover-effect--accent-simple"} style={inlineStyle} />
      <div
        className={classNames({
          "card-view--card_permanent-accent": props.showAccentOnCard,
        })}
        style={inlineStyle}
      />
      <div
        className={"card_hover-effect--accent-expandable"}
        style={inlineStyle}
      />
    </a>
  );
};

export default CardEvent;
