/**
 * Get widget slug from URL
 * @param widgetType
 * @returns {string|undefined}
 */
export const getWidgetSlugFromUrl = (widgetType) => {
  const url = new URL(window.location.href);
  const widgetSlug = url.searchParams.get(widgetType);

  return widgetSlug || undefined;
};

export const getWidgetItemSlugFromUrl = (widgetItemName) => {
  const url = new URL(window.location.href);
  const itemSlug = url.searchParams.get(widgetItemName);

  if (itemSlug) {
    const slug = itemSlug.split("----")[0];

    return slug;
  }

  return undefined;
};
