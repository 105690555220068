/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022
 */

import React, { useCallback, useContext } from "react";
import PropTypes from "prop-types";
import Location from "./Location";
import DateFormatter from "./DateFormatter";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import { formatDate } from "../../../../helpers/date";
import PrivateEventBadge from "../PrivateEventBadge";
import CalendarLinkingContext from "../../../../contexts/CalendarLinkingContext";

const EventRow = (props) => {
  const {
    event,
    showDetailsAsModal,
    showRSVP,
    showLocation,
    showDescription,
    showTimezone,
    showSummary,
    showEventDate,
    showRepeatIcon,
    showCTA,
    ctaLabel,
    ctaURL,
  } = props;

  //const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const { onClickEvent, getEventUrl } = useContext(CalendarLinkingContext);
  const { t } = useTranslation();
  const startDate = new Date(event.start_time * 1000 /* + tzOffset*/);
  const isFeatured = event.is_featured;

  const cursorPointer = showDetailsAsModal ? "dce-cursor-pointer" : "";

  /**
   *
   * @param e
   */
  const onRowClick = useCallback(
    (e, selector = null) => {
      return onClickEvent(event, { selector })(e);
    },
    [onClickEvent]
  );

  /**
   *
   * @param e
   */
  const onRSVPClick = useCallback(
    (e) => {
      onRowClick(e, `rsvp-${event.slug}`);
    },
    [onRowClick]
  );

  /**
   *
   * @param e
   */
  const onCtaClick = useCallback((e) => {
    e.stopPropagation();
  }, []);

  /**
   *
   * @param e
   */
  const onRecurringClick = useCallback(
    (e) => {
      onRowClick(e, `series-${event.slug}`);
    },
    [onRowClick]
  );

  const recurringBadge =
    showRepeatIcon && event.recurrence_series ? (
      <i
        className={classNames(
          "material-icons dce-dl-event-repeat",
          cursorPointer
        )}
        onClick={onRecurringClick}
      >
        repeat
      </i>
    ) : null;

  const descriptionElement = props.showDescription ? (
    <p
      className={classNames("line-clamp", { featured: isFeatured })}
      dangerouslySetInnerHTML={{ __html: event.description }}
    ></p>
  ) : null;

  const summaryElement = props.showSummary ? (
    <p
      className="line-clamp"
      dangerouslySetInnerHTML={{ __html: event.summary }}
    ></p>
  ) : null;

  const image = event.cover_image ? <img src={event.cover_image} /> : null;

  const formattedDate = showEventDate ? (
    <DateFormatter
      start_time={event.start_time}
      end_time={event.end_time}
      showTimezone={showTimezone}
      isAllDayEvent={event.allDay}
    />
  ) : null;

  const location = showLocation ? (
    <div className={"dce-detailed-list--row-content-details-location"}>
      <Location event={event} />
    </div>
  ) : null;

  const rsvp =
    showDetailsAsModal && showRSVP ? (
      <div
        className={classNames(
          "dce-detailed-list--row-content-details-rsvp",
          cursorPointer
        )}
        onClick={onRSVPClick}
      >
        RSVP Now
      </div>
    ) : null;

  const cta =
    showDetailsAsModal && showCTA && ctaURL ? (
      <a
        className={classNames(
          "dce-detailed-list--row-content-details-cta",
          cursorPointer
        )}
        href={ctaURL}
        target={`dce-detailed-list-${props.url}`}
        onClick={onCtaClick}
      >
        {ctaLabel}
      </a>
    ) : null;

  const description =
    showDescription && event.description && event.description.trim().length ? (
      <div className={"dce-detailed-list--row-content-details-description"}>
        {descriptionElement}
      </div>
    ) : null;

  const summary =
    showSummary && event.summary && event.summary.trim().length ? (
      <div className={"dce-detailed-list--row-content-details-description"}>
        {summaryElement}
      </div>
    ) : null;

  const featured = isFeatured ? (
    <span className={"dce-detailed-list--row-date-featured"}>
      <i className={"material-icons"}>bookmark</i> {t("Featured")}
    </span>
  ) : null;

  const privateEventBadge =
    event.is_private * 1 == 1 && !props.embedded ? <PrivateEventBadge /> : null;

  return (
    <div className={"dce-detailed-list--row"}>
      <div
        className={classNames("dce-detailed-list--row-date", {
          "dce-detailed-list--row-date_featured": isFeatured,
        })}
      >
        <div>
          <span className={"dce-detailed-list--row-date_month"}>
            {formatDate(startDate, { weekday: "short" })}
          </span>
          <span className={"dce-detailed-list--row-date_day"}>
            {startDate.getDate()}
          </span>
        </div>
      </div>
      <div className={"dce-detailed-list--row-content"}>
        <div className={"dce-detailed-list--row-content-details"}>
          <div className={"dce-detailed-list--row-content-details-date"}>
            {featured} {formattedDate} {recurringBadge} {privateEventBadge}
          </div>
          <div className={"dce-detailed-list--row-content-details-title"}>
            <a
              href={getEventUrl(event)}
              title={event.name}
              aria-label={`Event: ${event.name}`}
              onClick={onRowClick}
            >
              {" "}
              <h2 className={classNames(cursorPointer)}>{event.name}</h2>
            </a>
          </div>
          {location}
          {description}
          {summary}
          <div className="dce-detailed-list--row-content-details-footer-links">
            {rsvp}
            {cta}
          </div>
        </div>
        <div
          className={classNames(
            "dce-detailed-list--row-content-image",
            cursorPointer
          )}
          onClick={onRowClick}
        >
          {image}
        </div>
      </div>
    </div>
  );
};

EventRow.defaultProps = {};

EventRow.propTypes = {
  event: PropTypes.object,
  onSelectedEvent: PropTypes.func,
  showDetailsAsModal: PropTypes.bool,
  showRSVP: PropTypes.bool,
  showLocation: PropTypes.bool,
  showRSVPCTA: PropTypes.bool,
  showTimezone: PropTypes.bool,
  showEventDate: PropTypes.bool,
  showRepeatIcon: PropTypes.bool,
  showCTA: PropTypes.bool,
  ctaLabel: PropTypes.string,
  ctaURL: PropTypes.string,
};

export default EventRow;
