/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";
import classnames from "classnames";
import { CT } from "../../../../consts";
import classNames from "classnames";
import { formatDate } from "../../../../helpers/date";
import EventHeaderTime from "./EventHeaderTime";

export const LoaderIndicatorRow = ({}) => (
  <div
    className={
      "events-list--row-wrapper rev--loader-events-list-item rev-preloader-block"
    }
  >
    <div className="events-list--row">
      <div className="events-list--date-container"></div>
    </div>
  </div>
);

const LoaderIndicator = () => {
  return (
    <div className={"events-list-container "}>
      <LoaderIndicatorRow />
      <LoaderIndicatorRow />
      <LoaderIndicatorRow />
    </div>
  );
};

export default LoaderIndicator;
