/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useContext } from "react";
import classnames from "classnames";
import CalendarHeader from "../CalendarHeader";
import Events from "./Events";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import useAiSearch from "../../../../hooks/useAiSearch";
import SearchInput from "../AiSearch/SearchInput";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import LoaderIndicator from "./LoaderIndicator";

const EventList = (props) => {
  const { calendar, events } = props;
  const calendarID = "dce_calendar__" + calendar.model.uuid;

  const singleEvent = props.selectedEvent ? true : false;

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);
  const widgetSettings = useContext(WidgetSettingsContext);

  const hasInfiniteScroll =
    Number.parseInt(widgetSettings["infiniteScroll"]) === 1;

  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <PoweredBy align={hasInfiniteScroll ? "right" : "left"} />
    ) : null;

  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const eventsContainer = (
    <Events
      embedded={props.embedded}
      events={events.collection}
      selectedEvent={props.selectedEvent}
      calendarSlug={calendar.model.slug}
      ccbAddress={calendar.model.integration.address}
      onRSVPEvent={props.onRSVPEvent}
      loading={loading}
      error={props.calendar.rsvpError}
      onLoadMore={props.onLoadMore}
      hasMore={events.collection.length != events.totalRows}
      calendarID={calendarID}
      showWatermark={
        !props.selectedEvent && calendar.model && calendar.model.is_watermarked
      }
    />
  );

  const eventsListContent =
    (events.fetch && !hasInfiniteScroll) || !props.initialized ? (
      <LoaderIndicator />
    ) : (
      eventsContainer
    );

  const search =
    hasAIEnabled && widgetSettings["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  return (
    <div className={classnames(`dce--${CT.eventList}`)}>
      <CalendarHeader
        calendar={calendar}
        disabled={events.fetch}
        currentDate={props.selectedDate}
        onChangeDate={props.onSelectedDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
        onSearch={props.onKeywordSearch}
      />
      {search}
      {eventsListContent}
      {watermark}
    </div>
  );
};

EventList.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onLoadMore: PropTypes.func,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  selectedLocation: PropTypes.string,
  initialized: PropTypes.bool,
  selectedEvent: PropTypes.object,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onChangeFilter: PropTypes.func.isRequired,
  onKeywordSearch: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default EventList;
