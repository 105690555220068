/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";
import classnames from "classnames";
import { CT } from "../../../../consts";
import classNames from "classnames";

const Bubble = ({ bubbleSize, bubbleStyle }) => (
  <div
    className={classNames(
      "bubble-view--bubble",
      `bubble-view--bubble_size-${bubbleSize}`,
      "rev--loader-tile-item"
    )}
  >
    <div
      className={classNames(
        "bubble-view--bubble-image",
        `bubble-view--bubble-image_${bubbleStyle}`
      )}
    >
      <div className={"bubble-view--bubble-details"}></div>
    </div>
  </div>
);

const LoaderIndicator = ({ bubbleSize, bubbleStyle }) => {
  return (
    <div className={classnames("calendar-bubble-view_container")}>
      <Bubble bubbleSize={bubbleSize} bubbleStyle={bubbleStyle} />
      <Bubble bubbleSize={bubbleSize} bubbleStyle={bubbleStyle} />
      <Bubble bubbleSize={bubbleSize} bubbleStyle={bubbleStyle} />
      <Bubble bubbleSize={bubbleSize} bubbleStyle={bubbleStyle} />
    </div>
  );
};

export default LoaderIndicator;
