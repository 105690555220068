import React, { useContext } from "react";
import CTAButton from "./CTAButton";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import classNames from "classnames";

/**
 * Displays a Call to Action (CTA) button for an event.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.event - The event object.
 * @param {string} [props.settingsPrefix="cPopup"] - Specifies event type.
 * @param {string} [props.className=""] - Additional CSS class for the component.
 * @param {string} [props.buttonClassName=""] - Additional CSS class for the button.
 * @returns {JSX.Element|null} The rendered component or null if no CTA link is available.
 */
const EventCTA = ({
  event,
  settingsPrefix = "cPopup",
  className = "",
  buttonClassName = "",
}) => {
  const calendarSettingsContext = useContext(WidgetSettingsContext);

  // Determine primary CTA action and link based on event type.
  const primaryCtaAction =
    calendarSettingsContext[`${settingsPrefix}CTAAction1st`];
  const primaryCtaLink = event.ctas[primaryCtaAction] || null;
  const label = primaryCtaLink ? primaryCtaLink.label : "";

  // Check if the default label should be used.
  const isPrimaryCTADefaultLabel =
    Number.parseInt(
      calendarSettingsContext[`${settingsPrefix}1stDefaultCTALabel`]
    ) === 1;

  // Get the appropriate label to use.
  const primaryCTACustomLabel =
    calendarSettingsContext[`${settingsPrefix}1stCTALabel`];
  const labelToUse = isPrimaryCTADefaultLabel ? label : primaryCTACustomLabel;

  const openLinkInNewTab =
    Number.parseInt(calendarSettingsContext["openLinksNewTab"]) === 1;

  // Return null if no CTA link is available.
  if (!primaryCtaLink) {
    return null;
  }

  if (settingsPrefix === "cPopup") {
    return (
      <div className="event-row--details">
        <div className="event-row--details-row">
          <CTAButton
            label={labelToUse}
            url={primaryCtaLink.url}
            className={className}
            buttonClassName={buttonClassName}
            target={openLinkInNewTab ? "_blank" : "_self"}
          />
        </div>
      </div>
    );
  }

  return (
    <CTAButton
      label={labelToUse}
      url={primaryCtaLink.url}
      className={classNames(className, "dc_calendar_card-cta-action-position")}
      buttonClassName={buttonClassName}
      target={openLinkInNewTab ? "_blank" : "_self"}
    />
  );
};

export default EventCTA;
