import React from "react";
import classNames from "classnames";

/**
 * Renders a Call to Action (CTA) button component.
 *
 * @param {Object} props - The component props.
 * @param {string} props.url - The URL the button should navigate to.
 * @param {string} props.label - The label or text displayed on the button.
 * @param {string} props.target - The target attribute for the anchor tag.
 * @param {string} [props.className=""] - Additional CSS class for the component.
 * @param {string} [props.buttonClassName="dce--event-card--cta-btn"] - Additional CSS class for the button.
 * @returns {JSX.Element} The rendered CTA button component.
 */
const CTAButton = (props) => {
  const { className = "", buttonClassName = "dce--event-card--cta-btn" } =
    props;

  return (
    <div className={classNames(className)}>
      <a
        className={buttonClassName}
        href={props.url}
        target={props.target}
        dangerouslySetInnerHTML={{ __html: props.label }}
      ></a>
    </div>
  );
};

export default CTAButton;
