/**
 * Created by piotr.pozniak@thebeaverhead.com on 12/07/2022.
 */

import React, { useContext } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import LoadingIndicator from "../LoadingIndicator";
import { CT, PT } from "../../../../consts";
import PoweredBy from "../PoweredBy";
import CalendarHeader from "./CalendarHeader";
import NoEvents from "../NoEvents";
import EventRow from "./EventRow";
import Divider from "./Divider";
import SearchInput from "../AiSearch/SearchInput";
import useAiSearch from "../../../../hooks/useAiSearch";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import LoaderIndicator from "./LoaderIndicator";

const DetailedList = (props) => {
  const { calendar, events } = props;

  const calendarSettingsContext = useContext(WidgetSettingsContext);

  const { hasAIEnabled } = useAiSearch(calendar.model.integration);

  const loading =
    calendar.rsvp ||
    calendar.fetch ||
    calendar.delete ||
    calendar.update ||
    events.fetch;

  const showDetailsAsModal =
    Number.parseInt(calendarSettingsContext["generalOpenDetailsModal"]) === 1;

  const hasInfiniteScroll =
    Number.parseInt(calendarSettingsContext["infiniteScroll"]) === 1;

  const showDescription =
    Number.parseInt(calendarSettingsContext["dLShowDescription"]) === 1;

  const showRSVP =
    Number.parseInt(calendarSettingsContext["rsvpShowForOpenForAll"]) === 1;

  const showLocation =
    Number.parseInt(calendarSettingsContext["dLShowLocation"]) === 1;

  const showTimezone =
    Number.parseInt(calendarSettingsContext["generalShowTz"]) === 1;

  const showSummary =
    Number.parseInt(calendarSettingsContext["dLShowSummary"]) === 1;

  const showEventDate =
    Number.parseInt(calendarSettingsContext["dLShowDate"]) === 1;
  const showRepeatIcon =
    Number.parseInt(calendarSettingsContext["dLShowRepeatIcon"]) === 1;

  const showCTA =
    Number.parseInt(calendarSettingsContext["cDetailedShowCta1st"]) === 1;

  const primaryCtaAction = calendarSettingsContext["cDetailedCTAAction1st"];
  const isPrimaryCTADefaultLabel =
    Number.parseInt(calendarSettingsContext["cDetailed1stDefaultCTALabel"]) ===
    1;
  const primaryCTACustomLabel = calendarSettingsContext["cDetailed1stCTALabel"];

  const watermark =
    calendar.model && calendar.model.is_watermarked ? (
      <PoweredBy align={hasInfiniteScroll ? "right" : "left"} />
    ) : null;

  const calendarID = "dce_calendar__" + calendar.model.uuid;

  let previousDay = new Date(0);
  //const tzOffset = new Date().getTimezoneOffset() * 60 * 1000;

  const eventsList = events.collection.length ? (
    events.collection.map((i, idx) => {
      const eventStartTime = new Date(i.start_time * 1000 /* + tzOffset*/);
      const isOtherDay = eventStartTime.getMonth() != previousDay.getMonth();
      const divider = isOtherDay ? <Divider date={eventStartTime} /> : null;

      previousDay = isOtherDay ? eventStartTime : previousDay;

      const primaryCtaLink = i.ctas[primaryCtaAction] || null;
      const label = primaryCtaLink ? primaryCtaLink.label : "";
      const ctaLabel = isPrimaryCTADefaultLabel ? label : primaryCTACustomLabel;

      return (
        <React.Fragment>
          {divider}
          <EventRow
            key={i.slug}
            event={i}
            showDescription={showDescription}
            showDetailsAsModal={showDetailsAsModal}
            showRSVP={showRSVP}
            showLocation={showLocation}
            showTimezone={showTimezone}
            showSummary={showSummary}
            showEventDate={showEventDate}
            showRepeatIcon={showRepeatIcon}
            showCTA={showCTA}
            ctaLabel={ctaLabel}
            ctaURL={primaryCtaLink ? primaryCtaLink.url : ""}
          />
        </React.Fragment>
      );
    })
  ) : (
    <Divider date={props.selectedDate} />
  );

  const loadingIndicator = loading ? <LoaderIndicator /> : null;

  const noEvents = !loading && !events.collection.length ? <NoEvents /> : null;

  const search =
    hasAIEnabled && calendarSettingsContext["aiEnabled"] ? (
      <SearchInput
        searchEvents={props.searchEvents}
        fetchEvents={props.fetchEvents}
      />
    ) : null;

  return (
    <div className={classnames("dce-detailed-list", `dce--${CT.detailedList}`)}>
      <CalendarHeader
        disabled={events.fetch}
        calendar={calendar}
        currentDate={props.selectedDate}
        onChangeDate={props.onSelectedDateChange}
        events={events.collection}
        locations={events.locations}
        onUpcomingNavigationChange={props.onUpcomingNavigationChange}
        selectedFilters={props.selectedFilters}
        onChangeFilter={props.onChangeFilter}
      />
      {search}

      {noEvents}
      <InfiniteScrollWrapper
        className={classnames("dce-detailed-list")}
        onLoadMore={props.onLoadMore}
        hasMore={events.collection.length != events.totalRows}
        loading={loading}
        embedded={props.embedded}
      >
        {eventsList}
        {loadingIndicator}
      </InfiniteScrollWrapper>
      {watermark}

      {/*{modalEvent}*/}
    </div>
  );
};

DetailedList.propTypes = {
  calendar: PropTypes.object,
  events: PropTypes.object,
  onSelectedDateChange: PropTypes.func.isRequired,
  onUpcomingNavigationChange: PropTypes.func.isRequired,
  selectedDate: PropTypes.object,
  initialized: PropTypes.bool,
  embedded: PropTypes.bool,
  previewType: PropTypes.oneOf(Object.values(PT)),
  onLoadMore: PropTypes.func.isRequired,
  onChangeFilter: PropTypes.func.isRequired,
  selectedFilters: PropTypes.object.isRequired,
};

export default DetailedList;
