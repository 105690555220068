import React, { useContext, useMemo, useState } from "react";
import classnames from "classnames";
import PropTypes from "prop-types";
import ThisWeekButton from "./ThisWeekButton";
import NavButton from "./NavButton";
import WeeklistDropdown from "./WeeklistDropdown";
import { firstDayOfWeek, formatDate } from "../../../../../helpers/date";
import WidgetSettingsContext from "../../../../../contexts/WidgetSettingsContext";
import { getDateRange } from "../../../../../helpers/calendar";

const DateRangeSelector = (props) => {
  const currentWeek = new Date(props.currentWeek);
  const [state, setState] = useState({
    showWeeksDropdown: false,
  });

  const widgetSettings = useContext(WidgetSettingsContext);

  const eventsNumberOption = widgetSettings["eventsNumberOption"];
  const eventsNumberCount = widgetSettings["eventsNumber"];

  const canChangeToPreviousWeeks = useMemo(() => {
    if (eventsNumberOption !== "date_range") {
      return !(
        props.showUpcomingEventsOnly && currentWeek.isSameWeek(new Date())
      );
    }

    const [startDate, endDate] = getDateRange(
      eventsNumberOption,
      eventsNumberCount
    );

    if (!startDate) {
      return true;
    }

    return currentWeek > startDate;
  }, [
    props.showUpcomingEventsOnly,
    currentWeek,
    eventsNumberOption,
    eventsNumberCount,
  ]);
  const [startDate, endDate] = getDateRange(
    eventsNumberOption,
    eventsNumberCount
  );

  const canChangeToNextWeeks = useMemo(() => {
    if (eventsNumberOption !== "date_range") {
      return !props.showUpcomingEventsOnly;
    }

    if (!endDate) {
      return true;
    }

    return currentWeek < endDate;
  }, [
    props.showUpcomingEventsOnly,
    currentWeek,
    eventsNumberOption,
    eventsNumberCount,
  ]);

  /**
   *
   */
  const onClickThisWeek = () => {
    props.onChangeWeek(new Date());
  };

  /**
   *
   * @param direction
   * @returns {function(...[*]=)}
   */
  const onDateChange = (direction) => () => {
    setState({
      ...state,
      weekListStart: new Date(state.weekListStart).addWeeks(direction),
    });

    props.onChangeWeek(
      firstDayOfWeek(new Date(currentWeek).addWeeks(direction))
    );
  };

  const previousWeekButton =
    props.allowChangingWeeks && canChangeToPreviousWeeks ? (
      <NavButton
        className={"prev"}
        icon={"keyboard_arrow_left"}
        onDateChange={onDateChange}
        direction={-1}
      />
    ) : null;

  const nextWeekButton =
    props.allowChangingWeeks && canChangeToNextWeeks ? (
      <NavButton
        className={"next"}
        icon={"keyboard_arrow_right"}
        onDateChange={onDateChange}
        direction={1}
      />
    ) : null;

  const weeksListDropdown = props.allowChangingWeeks ? (
    <WeeklistDropdown
      currentWeek={currentWeek}
      showUpcomingEventsOnly={props.showUpcomingEventsOnly}
      onChangeWeek={props.onChangeWeek}
      startDate={startDate}
      endDate={endDate}
    />
  ) : null;

  return (
    <div className={"date-range-selector--container"}>
      <ThisWeekButton currentWeek={currentWeek} onClick={onClickThisWeek} />

      <div className="date-range-selector__week-selector-arrow">
        <div className="date-range-selector__week-selector-arrow--navigation">
          {previousWeekButton}

          <div className={"date-range-selector-week-dropdown-container"}>
            <div
              className={classnames("date-range-selector-week-dropdown-value", {
                "without-weeks-dropdown": !props.allowChangingWeeks,
              })}
            >
              {formatDate(currentWeek, { month: "short", day: "numeric" })} -{" "}
              {formatDate(
                new Date(currentWeek.setDate(currentWeek.getDate() + 6)),
                { month: "short", day: "numeric", year: "numeric" }
              )}
            </div>
            {weeksListDropdown}
          </div>
          {nextWeekButton}
        </div>
      </div>
    </div>
  );
};

DateRangeSelector.propTypes = {
  currentWeek: PropTypes.object,
  onChangeWeek: PropTypes.func.isRequired,
  allowChangingWeeks: PropTypes.bool,
};

export default DateRangeSelector;
