/**
 * Created by piotr.pozniak@thebeaverhead.com on 18/12/2023
 */

import React, { useEffect, useMemo } from "react";
import PropTypes from "prop-types";
import { CT } from "../../../consts";
import CardView from "../components/CardView/CardView";
import CardSlider from "../components/CardView/CardSlider";
import BubbleView from "../components/Bubble/BubbleView";
import { getAllOptionValues, updateStylesheet } from "../../../builder";
import { WT } from "../../admin/consts/widget";
import classNames from "classnames";
import WidgetSettingsContext from "../../../contexts/WidgetSettingsContext";

const CalendarComponentsMapping = {
  [CT.card]: CardView,
  [CT.cardSlider]: CardSlider,
  [CT.cardList]: CardView,
  [CT.bubble]: BubbleView,
};

const onUpcomingNavigationChange = () => {};
const onSelectedDateChange = () => {};
const onLoadMore = () => {};

const FeaturedHighlights = (props) => {
  const calendarUUID = props.calendar.model.uuid;
  const calendar = {
    model: {
      template:
        props.calendar.model.widget_settings.featured_template || CT.bubble,
      uuid: calendarUUID,
      slug: props.calendar.model.slug,
      integration: props.calendar.model.integration,
      widget_settings: props.calendar.model.widget_settings,
      builder: props.calendar.model.builder,
      widget_type: WT.calendar,
    },
  };

  useEffect(() => {
    // console.log(calendar.model.template, calendar.model.uuid);
    updateStylesheet(calendar.model, "highlights");
  }, []);

  const CalendarComponent =
    CalendarComponentsMapping[
      calendar.model.widget_settings.featured_template || CT.bubble
    ];

  const calendarSettings = getAllOptionValues(calendar.model, "highlights");

  // if (!props.events.collection.length) {
  //   return null;
  // }
  return (
    <div className={classNames("dc-highlights")}>
      <div className="dce__h1">{calendarSettings["cFeaturedLabel"]}</div>
      <div className={"dc-highlights_container"}>
        <WidgetSettingsContext.Provider value={calendarSettings}>
          <div className={`dce_calendar__${calendarUUID} _highlights`}>
            <CalendarComponent
              key={`${calendar.model.uuid}_featured`}
              {...props}
              onSelectedDateChange={onSelectedDateChange}
              onUpcomingNavigationChange={onUpcomingNavigationChange}
              onLoadMore={onLoadMore}
              calendar={calendar}
              events={props.events}
            />
          </div>
        </WidgetSettingsContext.Provider>
      </div>
    </div>
  );
};

FeaturedHighlights.defaultProps = {};

FeaturedHighlights.propTypes = {
  eventsLimit: PropTypes.number,
};

export default FeaturedHighlights;
