import {
  getOriginEventPageUrl,
  getSharedEventUrl,
  removeEventUrlQueryParams,
} from "../../../helpers/calendar";
import { useHistory } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { getWidgetItemSlugFromUrl } from "../../../helpers/linking";

export const useEventLinking = (calendarSlug, event, widgetSettings) => {
  const history = useHistory();
  const [selectedEventSlug, setSelectedEventSlug] = useState(event?.slug);
  const [selectedEvent, setSelectedEvent] = useState(event);

  const [originEventsSlug, setOriginEventsSlug] = useState(
    getWidgetItemSlugFromUrl("event")
  );

  const openChurchCenter =
    Number.parseInt(widgetSettings["generalGoToChurchCenter"]) === 1;

  const primaryCtaAction = widgetSettings[`cCardCTAAction1st`];

  const triggerCTAOnCardClick =
    Number.parseInt(widgetSettings["cCardActionOnClick"]) === 1;

  const openLinkInNewTab =
    Number.parseInt(widgetSettings["openLinksNewTab"]) === 1;

  // const showDetailsAsModal =
  //   Number.parseInt(widgetSettings["generalOpenDetailsModal"]) === 1;

  const expandDetailsAsAccordionValue =
    widgetSettings["generalExpandDetailAccording"] && !originEventsSlug;
  const showDetailsAsModalValue =
    widgetSettings["generalOpenDetailsModal"] || originEventsSlug;

  const expandDetailsAsAccordion = expandDetailsAsAccordionValue ? true : false;

  const showDetailsAsModal = showDetailsAsModalValue ? true : false;

  useEffect(() => {
    setSelectedEventSlug(event?.slug);
    setSelectedEvent(event);
  }, [event]);

  /**
   *
   * @param _event
   * @returns {*|`/${*}/event/${string}`}
   */
  const getEventUrl = (_event) => {
    const primaryCtaLink = _event?.ctas[primaryCtaAction] || null;

    if (triggerCTAOnCardClick && primaryCtaLink) {
      return primaryCtaLink.url;
    } else if (openChurchCenter) {
      return getOriginEventPageUrl(_event);
    }
    return getSharedEventUrl(calendarSlug, _event.slug, _event.name);
  };

  /**
   *
   * @type {function(*): function(*): (undefined|boolean)}
   */
  const onClickEvent = useCallback(
    (_event, params = {}) =>
      (e) => {
        const primaryCtaLink = event?.ctas[primaryCtaAction] || null;
        if (triggerCTAOnCardClick && primaryCtaLink) {
          // open primaryCtaLink.url link in new tab if openLinkInNewTab is true
          window.open(
            primaryCtaLink.url,
            openLinkInNewTab ? event.slug : "_self"
          );
          return;
        }

        e.preventDefault();
        e.stopPropagation();

        if (openChurchCenter) {
          window.open(
            getOriginEventPageUrl(_event),
            openLinkInNewTab ? _event.slug : "_self"
          );
          return false;
        } else {
          if (showDetailsAsModal || expandDetailsAsAccordion) {
            history.push(
              getSharedEventUrl(calendarSlug, _event.slug, _event.name, params)
            );
          }
        }
        // props.onSelectedEvent(event);
        return false;
      },
    [
      openChurchCenter,
      openLinkInNewTab,
      history,
      calendarSlug,
      showDetailsAsModal,
    ]
  );

  const onCloseOpenedEventsDetails = useCallback(
    (e) => {
      if (e) {
        e.preventDefault();
        e.stopPropagation();
      }
      setSelectedEvent(null);
      setOriginEventsSlug(null);
      history.push(
        removeEventUrlQueryParams(["calendar", "event", "selector"])
      );
    },
    [selectedEvent]
  );

  return {
    onClickEvent,
    selectedEventSlug,
    selectedEvent,
    getEventUrl,
    onCloseOpenedEventsDetails,
    originEventsSlug,
    showDetailsAsModal,
    expandDetailsAsAccordion,
  };
};

export default useEventLinking;
