/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";
import classnames from "classnames";
import { CT } from "../../../../consts";
import classNames from "classnames";
import { formatDate } from "../../../../helpers/date";

const Card = ({}) => (
  <div
    className={
      "dce-detailed-list--row rev--loader-detailed-item rev-preloader-block"
    }
  >
    {/*<div className={classNames("dce-detailed-list--row-date", {})}>
      <div>
        <span className={"dce-detailed-list--row-date_month"}></span>
        <span className={"dce-detailed-list--row-date_day"}></span>
      </div>
    </div>
    <div className={"dce-detailed-list--row-content"}>
      <div className={"dce-detailed-list--row-content-details"}>
        <div className={"dce-detailed-list--row-content-details-date"}></div>
        <div className={"dce-detailed-list--row-content-details-title"}></div>
        <div className="dce-detailed-list--row-content-details-footer-links"></div>
      </div>
      <div className={classNames("dce-detailed-list--row-content-image")}></div>
    </div>*/}
  </div>
);

const LoaderIndicator = ({}) => {
  return (
    <>
      <Card />
    </>
  );
};

export default LoaderIndicator;
