/**
 * Created by piotr.pozniak@thebeaverhead.com on 14/11/2024
 */

import React from "react";
import classnames from "classnames";
import { CT } from "../../../../consts";
import classNames from "classnames";

const Card = ({}) => (
  <div
    className={classNames(
      "card-slider_card-wrapper rev--loader-slider-item rev-preloader-block"
    )}
  >
    <div className={"card-view--card "}></div>
  </div>
);

const LoaderIndicatorSlider = ({}) => {
  return <Card />;
};

export default LoaderIndicatorSlider;
