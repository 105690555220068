/**
 * Created by piotr.pozniak@thebeaverhead.com on 13/10/2018.
 */

import React, { useCallback, useContext, useEffect, useState } from "react";
import Row from "./Row";
import PropTypes from "prop-types";
import LoadingIndicator from "../LoadingIndicator";
import RowModal from "./RowModal";
import InfiniteScrollWrapper from "../InfiniteScrollWrapper";
import NoEvents from "../NoEvents";
import {
  getSharedEventUrl,
  openEventPage,
  removeEventUrlQueryParams,
} from "../../../../helpers/calendar";
import WidgetSettingsContext from "../../../../contexts/WidgetSettingsContext";
import { useHistory } from "react-router-dom";
import { getWidgetItemSlugFromUrl } from "../../../../helpers/linking";
import CalendarLinkingContext from "../../../../contexts/CalendarLinkingContext";
import { LoaderIndicatorRow } from "./LoaderIndicator";

const Events = (props) => {
  const { selectedEvent, selectedEventSlug } = useContext(
    CalendarLinkingContext
  );

  const [originEventsSlug, setOriginEventsSlug] = useState(
    getWidgetItemSlugFromUrl("event")
  );

  const widgetSettings = useContext(WidgetSettingsContext);

  /**
   *
   * @param rowId
   */

  const events = props.events || [];
  const { loading, hasMore, embedded } = props;

  const noEvents = !loading && !events.length ? <NoEvents /> : null;

  const expandDetailsAsAccordionValue =
    widgetSettings["generalExpandDetailAccording"] && !originEventsSlug;
  const showDetailsAsModalValue =
    widgetSettings["generalOpenDetailsModal"] || originEventsSlug;

  const expandDetailsAsAccordion = expandDetailsAsAccordionValue ? true : false;

  const showDetailsAsModal = showDetailsAsModalValue ? true : false;

  const rows = events.length
    ? events.map((event, idx) => {
        return (
          <Row
            key={event.integration_event_id + "_" + idx}
            event={event}
            showDetails={props.selectedEvent === event.slug}
            calendarSlug={props.calendarSlug}
            embedded={embedded}
            ccbAddress={props.ccbAddress}
            onRSVPEvent={props.onRSVPEvent}
            loading={props.loading}
            error={props.error}
            /*closeOpenedEventsDetails={onCloseOpenedEventsDetails}*/
            expandedRowSlug={selectedEventSlug}
            expandAsAccordion={expandDetailsAsAccordion}
            expandAsModal={showDetailsAsModal}
          />
        );
      })
    : noEvents;

  const loadingIndicator = loading ? <LoaderIndicatorRow /> : null;

  return (
    <div
      className={
        "events-list-container " +
        (!events.length ? "events-list-container--empty" : "")
      }
    >
      <InfiniteScrollWrapper
        onLoadMore={props.onLoadMore}
        hasMore={hasMore}
        loading={loading}
        embedded={embedded}
      >
        {rows}
      </InfiniteScrollWrapper>
      {loadingIndicator}
    </div>
  );
};

Events.propTypes = {
  loading: PropTypes.bool,
  selectedEvent: PropTypes.object,
  calendarSlug: PropTypes.string,
  calendarID: PropTypes.string,
  embedded: PropTypes.bool,
  ccbAddress: PropTypes.string,
  onLoadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  showWatermark: PropTypes.bool,
  /**
   * an optional callback, this is to notify that modal row has been closed.
   * Used to close events list modal in case there is only one event on the list
   * so closing the event row modal should close the events list
   */
  onClosedEventRowModal: PropTypes.func,
};
//Events.whyDidYouRender = true
export default Events;
